import {Page, Link, Text, View, Document, StyleSheet, Rect, LinearGradient, Svg, Defs, Stop, Font, Line, Image} from '@react-pdf/renderer';

import FBRL from "./FBRL_Portrait"
import TGLogo from "./TGLogo"
import Copyright from "./Copyright"
import SSP_Regular from '../fonts/SourceSansPro-Regular.ttf'
import SSP_Bold from '../fonts/SourceSansPro-Bold.ttf'
import SSP_SemiBold from '../fonts/SourceSansPro-SemiBold.ttf'
import SSP_Italic from '../fonts/SourceSansPro-Italic.ttf'


Font.register({family: 'SSP_Regular', fonts: [{ src: SSP_Regular }]});
Font.register({family: 'SSP_Bold', fonts: [{ src: SSP_Bold, fontWeight: 'bold' }]});
Font.register({family: 'SSP_SemiBold', fonts: [{ src: SSP_SemiBold, fontWeight: 'semibold' }]});
Font.register({family: 'SSP_Italic', fonts: [{ src: SSP_Italic, fontWeight: 'normal', fontStyle: 'italic'}]});

// Create styles
const styles = StyleSheet.create({
    page: { flexDirection: 'column', backgroundColor: '#F1F1F1' },
    viewer: {   width: '65vw', height: '80vh' },
    section: {  margin: 0,  paddingTop: '40px' },
    textSemiBold: {
        position: "absolute",
        color: 'white',
        fontFamily: 'SSP_SemiBold',
        fontSize: '18px',
        marginLeft: '40px'
    },
    textRegular: {
        position: "absolute",
        color: 'white',
        fontFamily: 'SSP_Regular',
        letterSpacing: '0.36px',
        fontSize: '12px',
        marginLeft: '40px'
    },
    textRegularBlack: {
        position: "absolute",
        color: 'black',
        fontFamily: 'SSP_Regular',
        letterSpacing: '0.36px',
        fontSize: '14px',
        marginLeft: '40px'
    },
    soilTextSemiBold: {
        position: "absolute",
        color: 'black',
        fontFamily: 'SSP_SemiBold',
        letterSpacing: '0.36px',
        fontSize: '14px',
        marginLeft: '40px'
    }
  });

  const planDetails = {
    "TruPro": {desc: "Our comprehensive lawn care plan"},
    "TruCore": {desc: "Our advanced plan to protect and care for your lawn"},
    "TruYou": {desc: "Our standard lawn plan that cares for your lawn and treats weeds"},
    "TruBasic": {desc: "Partial season lawn services that feed your lawn and treat for weeds"},
  }

const year = new Date().getFullYear();

 const MyDoc = (props) => {
   let textY = 40;
   let marginLeft = 160;
   const {data, tcRating, showQR, result} = props
   const temp = data.Temperature.split(",");
   const moisture = data.MoisturePercentage.split(",");
   const weeds = data.WeedIdentification.split(",");
   const turfColorScore = data.TurfColoring.split(",");
   const newDate = new Date().yyyymmdd().split("_")[0]
   const formDate = newDate.substring(4,6)+'/'+newDate.substring(6)+'/'+newDate.substring(0,4)
   const isLawnDormant = data.IsLawnDormant

    //console.log(result);
    return (
    //   <div style={styles.viewer}>
    //   <PDFViewer height={'90%'} width={'100%'}>
      <Document>
        <Page size="A4" style={styles.page} orientation='portrait'>
            <View style={{width: '600px', height: '40px', marginTop: '0px',  backgroundColor: 'white'}} fixed>
                <TGLogo />
            </View>
            <View style={{...styles.section, paddingTop: '0'}}>
                <Svg viewBox="0 0 100vw 38vh" >
                    <Defs>
                        <LinearGradient id="userInput">
                            <Stop offset="1%" stopColor="#046A38" />
                            <Stop offset="95%" stopColor="#00B140" />
                        </LinearGradient>
                    </Defs>
                    <Rect height={'100'} width={'100'} fill='url("#userInput")'/>
                </Svg>
                <Text style={{ top: `${textY-=25}px`, ...styles.textSemiBold}} >Your Lawn Assessment</Text>
                <Text style={{ top: `${textY-10}px`, right: "10px", ...styles.textRegular}} >Date: {formDate}</Text>
                
                <Text style={{ top: `${textY+=45}px`, ...styles.textRegular}} >EMPLOYEE NAME</Text>
                <Text style={{ top: `${textY+=20}px`, ...styles.textRegular}} >BRANCH EMAIL</Text>
                <Text style={{ top: `${textY+=20}px`, ...styles.textRegular}} >EMPLOYEE PHONE</Text>
                
                <Text style={{ top: `${textY+=30}px`, ...styles.textRegular}} >CUSTOMER NAME</Text>
                <Text style={{ top: `${textY+=20}px`, ...styles.textRegular}} >CUSTOMER PHONE</Text>
                <Text style={{ top: `${textY+=20}px`, ...styles.textRegular}} >CUSTOMER EMAIL</Text>
                <Text style={{ top: `${textY+=20}px`, ...styles.textRegular}} >CUSTOMER ADDRESS</Text>

                <Text style={{ ...styles.textRegular, marginLeft: `${marginLeft}px`, top: `${textY-=130}px`}} > -   {result.empName}</Text>
                <Text style={{ ...styles.textRegular, marginLeft: `${marginLeft}px`, top: `${textY+=20}px`}} > -   {result.branchEmail}</Text>
                <Text style={{ ...styles.textRegular, marginLeft: `${marginLeft}px`, top: `${textY+=20}px`}} > -   {result.empPhone?`${result.empPhone.substring(0,3)}-${result.empPhone.substring(3,6)}-${result.empPhone.substring(6)}`:"NA"}</Text>

                <Text style={{ ...styles.textRegular, marginLeft: `${marginLeft}px`, top: `${textY+=30}px`}} > -   {result.custName}</Text>
                <Text style={{ ...styles.textRegular, marginLeft: `${marginLeft}px`, top: `${textY+=20}px`}} > -   {result.custPhone?`${result.custPhone.substring(0,3)}-${result.custPhone.substring(3,6)}-${result.custPhone.substring(6)}`:"NA"}</Text>
                <Text style={{ ...styles.textRegular, marginLeft: `${marginLeft}px`, top: `${textY+=20}px`}} > -   {result.custEmail?.trim() === ""?"NA":result.custEmail}</Text>
                <Text style={{ ...styles.textRegular, marginLeft: `${marginLeft}px`, top: `${textY+=20}px`}} > -   </Text>
                <Text style={{ ...styles.textRegular, marginLeft: `${marginLeft+12}px`, top: `${textY}px`}} >{data.CustomerAddress}</Text>
            </View>

            <View style={{...styles.section, marginTop: '10px', width: '57vw', marginLeft: '-10px', backgroundColor: 'white', borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px', border: '0px solid black'}} >
                {/* <Text style={{ ...styles.soilTextSemiBold ,marginLeft: '20px', top: `${textY-=115}px`}} >SOIL READINGS</Text> */}
                <Text style={{ ...styles.soilTextSemiBold, fontSize: '13px', marginLeft: `${marginLeft-70}px`, top: `${textY-=170}px`}} >SOIL</Text>
                <Text style={{ ...styles.soilTextSemiBold, fontSize: '13px', marginLeft: `${marginLeft+20}px`, top: `${textY}px`}} >SOIL</Text>
                <Text style={{ ...styles.soilTextSemiBold, fontSize: '13px', marginLeft: `${marginLeft+92}px`, top: `${textY}px`}} >GREEN INDEX</Text>
                
                <Text style={{ ...styles.soilTextSemiBold, fontSize: '13px', marginLeft: `${marginLeft-80}px`, top: `${textY+=20}px`}} >TEMP(F)</Text>
                <Text style={{ ...styles.soilTextSemiBold, fontSize: '13px', marginLeft: `${marginLeft-2}px`, top: `${textY}px`}} >MOISTURE(%) </Text>
                <Text style={{ ...styles.soilTextSemiBold, fontSize: '13px', marginLeft: `${marginLeft+110}px`, top: `${textY}px`}} >SCORE  </Text>

                <FBRL label="F" temp={temp[0]} moisture={moisture[0]} greenIndexScore={turfColorScore[0]} divMargin={-10} />{/* Fornt */}
                <FBRL label="B" temp={temp[1]} moisture={moisture[1]} greenIndexScore={turfColorScore[1]} divMargin={-40} />{/* Back */}
                <FBRL label="R" temp={temp[2]} moisture={moisture[2]} greenIndexScore={turfColorScore[2]} divMargin={-40} />{/* Right */}
                <FBRL label="L" temp={temp[3]} moisture={moisture[3]} greenIndexScore={turfColorScore[3]} divMargin={-40} />{/* Left */}

            </View>

            <View style={{...styles.section, width: '45vw', marginLeft: '328px', marginTop: '-226px', backgroundColor: 'white', borderTopRightRadius: '0px', borderBottomRightRadius: '0px', border: '0px solid black'}} >
                <Svg height="206" width="15" style={{marginTop: '-20px'}}>
                    <Line x1={15} y1={-10} x2={15} y2={200} stroke='black' strokeWidth={2}/>
                </Svg>
                <Text style={{ ...styles.soilTextSemiBold, top: `${textY-=10}px`, textDecoration: 'underline'}} >WEED IDENTIFIED</Text>
                <Text style={{ ...styles.textRegularBlack, marginLeft: `${marginLeft-120}px`, top: `${textY+=60}px`}} >WEED 1: {weeds[0] === ""?"None":weeds[0]}</Text>
                <Text style={{ ...styles.textRegularBlack, marginLeft: `${marginLeft-120}px`, top: `${textY+=30}px`}} >WEED 2: {weeds[1] === ""?"None":weeds[1]}</Text>
                <Text style={{ ...styles.textRegularBlack, marginLeft: `${marginLeft-120}px`, top: `${textY+=30}px`}} >WEED 3: {weeds[2] === ""?"None":weeds[2]}</Text>
                <Text style={{ ...styles.textRegularBlack, marginLeft: `${marginLeft-120}px`, top: `${textY+=30}px`}} >WEED 4: {weeds[3] === ""?"None":weeds[3]}</Text>
            </View>
            
            {/* <Text break></Text> */}

            <View style={{...styles.section, paddingLeft: '10px'}}>
                <Text style={{...styles.soilTextSemiBold, marginTop: '15px', paddingLeft: '-25px'}}>ASSESSMENT RESULT</Text>
                <Svg width={160} height={240} style={{marginTop: '-10px'}}>
                    <Rect x={0} y={20} rx={15} ry={15} width={160} height={200} fill='white' />
                    <Rect x={0} y={20} rx={5} ry={5} width={161} height={30} fill='#046A38' />
                </Svg>
                <Text style={{...styles.soilTextSemiBold, color: 'white', marginTop: '55px', marginLeft: '50px'}}>TURF DENSITY</Text>
                <Image src={require(`../image/Turf${data.TurfDensity}.png`)} style={{width: '140px', height: '140px', position: 'absolute', top: '95px', left: '19px'}} />
            </View>

            <View style={{...styles.section, paddingLeft: '180px', marginTop: '-300px'}}>
                <Svg width={160} height={240}>
                    <Rect x={0} y={40} rx={15} ry={15} width={160} height={200} fill='white' />
                    <Rect x={0} y={40} rx={5} ry={5} width={161} height={30} fill='#046A38' />
                </Svg>
                <Text style={{...styles.soilTextSemiBold, color: 'white', marginTop: '85px', right: '290px'}}>WEED DENSITY</Text>
                <Image src={require(`../image/Weed${data.WeedDensity}.png`)} style={{width: '140px', height: '140px', position: 'absolute', top: '125px', left: '189px'}} />
            </View>

            <View style={{...styles.section, marginTop: '-240px', paddingLeft: '350px'}}>
                <Svg width={235} height={240}>
                    <Rect x={0} y={0} rx={15} ry={15} width={235} height={200} fill='white' />
                    <Rect x={0} y={0} rx={5} ry={5} width={236} height={30} fill='#046A38' />
                </Svg>
                <Text style={{...styles.soilTextSemiBold, color: 'white', marginTop: '45px', right: '90px'}}>TURF COLOR</Text>
                <Image src={require(`../image/TurfColor${tcRating}.png`)} style={{width: '140px', height: '140px', position: 'absolute', top: '85px', left: '358px'}}  />
                <Image src={require(`../image/TGLegend.PNG`)} style={{width: '90', height: '130px', position: 'absolute', top: '90px', left: '495px'}}  />
            </View>

            <Text break></Text>

            {isLawnDormant && <View style={{...styles.section, paddingLeft: '5px'}}>
                <Svg width={580} height={110} style={{marginTop: '-50px'}}>
                    <Rect x={10} y={40} rx={5} ry={5} width={565} height={70} stroke='black' />
                </Svg>
                <Text style={{...styles.soilTextSemiBold, fontSize: '12px', color: 'black', marginTop: '21px', marginLeft: '30px', paddingHorizontal: '10px', backgroundColor: '#F1F1F1'}}>No Color Rating Obtained</Text>
                <Text style={{...styles.textRegularBlack, fontSize: '10px', color: 'black', marginTop: '39px', marginLeft: '27px', width: '540px'}}>A color rating was not included as part of your lawn assessment, due to minimal growth occurring in lawn grasses currently. As temperatures warm up, fertilizer applications included in our recommended program will help promote a dark green color and improve the Turf Density in your lawn. Weed control applied will also help decrease the presence of weeds throughout the season.</Text>
            </View>}

            <View style={{...styles.section, paddingLeft: '10px'}}>
                <Svg width={580} height={80} style={{marginTop: '-50px'}}>
                    <Defs>
                        <LinearGradient id="ls1">
                            <Stop offset="1%" stopColor="#046A38" />
                            <Stop offset="95%" stopColor="#00B140" />
                        </LinearGradient>
                    </Defs>
                    <Rect x={140} y={40} rx={5} ry={5} width={300} height={40} fill='url("#ls1")' />
                </Svg>
                <Text style={{...styles.soilTextSemiBold, fontSize: '16px', color: 'white', marginTop: '40px', marginLeft: '198px'}}>TOTAL LAWN SCORE</Text>
                <Text style={{...styles.soilTextSemiBold, fontSize: '18px', color: 'white', marginTop: '38px', marginLeft: '382px'}}>{data.TotalLawnScore*10}</Text>
            </View>
            
            <View style={{...styles.section, marginTop: '10px', paddingLeft: '10px'}}>
                <Text style={{...styles.soilTextSemiBold, marginTop: '20px', paddingLeft: '-25px'}}>RECOMMENDED PROGRAM</Text>
                <Svg width={575} height={250}>
                    <Rect x={0} y={10} rx={15} ry={15} width={575} height={210} fill='white' />
                </Svg>
                <Image src={require(`../image/PlanImage@2x.png`)} style={{width: '190px', height: '190px', position: 'absolute', top: '60px', left: '20px'}} />
                <Text style={{...styles.soilTextSemiBold, fontSize: '28px', fontFamily: 'SSP_Bold', color: '#046A38', marginTop: '65px', marginLeft: '225px'}}>{data.RecommendedPlan}</Text>
                <Text style={{...styles.textRegular, color: '#046A38', marginTop: '100px', marginLeft: '225px', width: '320px'}}>{planDetails[data.RecommendedPlan].desc}</Text>
                
                <Text style={{...styles.soilTextSemiBold, marginTop: `${textY-50+20}px`, marginLeft: '225px'}}>Lawn Applications Included:   {data.LawnApplicationIncluded}</Text>
                <Text style={{...styles.soilTextSemiBold, marginTop: `${textY-50+65}px`, marginLeft: '225px', marginRightt: '10px', width: '320px'}}>Expanded Services Included:   {data.ExpendedServiceIncluded}</Text>
                
            </View>

            {showQR && <View style={{...styles.section, marginTop: '-40px', marginLeft: '180px', width: '250px'}}>
                <Link src='https://www.trugreen.com/lawn-care-101/blog/lawn-care-tips/myth-busting-keeping-your-lawn-well-watered' >
                    <Image src={require(`../image/LawnQRcode.png`)} style={{width: '250px', height: '220px', position: 'relative', top: '0px', left: '0px'}} />
                </Link>
            </View>}

            <View style={{...styles.section, position: 'absolute', paddingTop: '3vh', bottom: 0, width: '100vw', backgroundColor: '#666666'}} fixed>
                <Copyright/>
                <Text style={{...styles.textRegular, fontSize: '10px', marginTop: '10px'}} >  {year} TruGreen Limited Partnership. All rights reserved.</Text>
                <Text style={{...styles.textRegular, top: '10px', right: '15px'}} render={({ pageNumber, totalPages }) => ( `${pageNumber} / ${totalPages}` )} ></Text>
            </View>

        </Page>
      </Document>
   )
}

//eslint-disable-next-line
export const ymd = () => Date.prototype.yyyymmdd = function() {
    let mm = this.getMonth() + 1; // getMonth() is zero-based
    let dd = this.getDate();

    let hh = this.getHours()
    let min = this.getMinutes()
    let ss = this.getSeconds()
  
    return [this.getFullYear(),
            (mm>9 ? '' : '0') + mm,
            (dd>9 ? '' : '0') + dd,
            "_",
            (hh>9 ? '' : '0') + hh,
            (min>9 ? '' : '0') + min,
            (ss>9 ? '' : '0') + ss
           ].join('');
  };


export default MyDoc