import {useForm} from 'react-hook-form'
import * as yup from 'yup'
import {yupResolver} from "@hookform/resolvers/yup"
import { useEffect, useState } from 'react'
import Tile from './Tile'
import Counter from './Counter'
import FBRL from './FBRL'
import WeedType from './WeedType'
import {motion} from 'framer-motion';
import { useNavigate, useSearchParams } from 'react-router-dom'
import dropDown from "./image/chevronDown.svg"
import dropUp from "./image/chevronUp.svg"
import AddressAutoComplete from './AddressAutoComplete'
import ReactLoading from 'react-loading';

import SelectSearch from 'react-select-search';
import "./selectStyle.css"

import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { faMailBulk } from '@fortawesome/free-solid-svg-icons'

const Form = () => {

    const [tcf, setTcf] = useState("")
    const [tcb, setTcb] = useState("")
    const [tcr, setTcr] = useState("")
    const [tcl, setTcl] = useState("")

    const [searchParams] = useSearchParams();
    
    const [turfDensity, setTurfDensity] = useState("")
    const [weedDensity, setWeedDensity] = useState("")
    
    const [grassType, setGrassType] = useState(null)
    const [weed1, setWeed1] = useState(null)
    const [weed2, setWeed2] = useState(null)
    const [weed3, setWeed3] = useState(null)
    const [weed4, setWeed4] = useState(null)

    const [loading, setLoading] = useState(false)
    const [isopen, setIsopen] = useState(false)

    const [tColor, setTColor] = useState(0)
    const [lawnScore, setLawnScore] = useState(0)
    const [isLawnDormant, setIsLawnDormant] = useState(false)


    const [temperature, setTemperature] = useState(['', '', '', ''])
    const [moisture, setMoisture] = useState(['', '', '', ''])
    
    const [openDropDown, setOpenDropDown] = useState(!(searchParams.get("address")))
    const [addressval, setAddressVal] = useState(searchParams.get("address") || "")
    
    //decode the url
    // const salt = 'Ch_2ipHOdr7sPudU?rl$';
    // // Decrypt function to decrypt value
    // const decrypt = (salt, encoded) => {
    //     const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0));
    //     const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
    //     return encoded.match(/.{1,2}/g)
    //         .map((hex) => parseInt(hex, 16))
    //         .map(applySaltToChar)
    //         .map((charCode) => String.fromCharCode(charCode))
    //         .join('');
    // };
    // const decodeEncryptedValue = (encodedValue) => {
    //     return encodedValue ? decrypt(salt, encodedValue) : "";
    // };

    // Extract and decode URL parameters
    const employeeName =searchParams.get("ename");
    const branchEmail =searchParams.get("bmail");
    const employeePhone =searchParams.get("ephn") ? searchParams.get('ephn'): '';
    const customerName =searchParams.get("cname");
    const customerPhone =searchParams.get("cphn") ? searchParams.get('cphn'): '';
    const customerEmail =searchParams.get("cmail");
    const customerID =searchParams.get("extcustomerid");
    const OpportunityID =searchParams.get("extopportuniotyid");
    const lead_ID = searchParams.get("extleadid") || "";
    // const lead_ID=6377373;
    // const branchid="5024";
    
    const navigate = useNavigate();

    const loadAddress = (e) => {
        const val = e.target.value
        delete errors.cAddress
        setAddressVal(val)
        if(val.length > 3)
            setOpenDropDown(true)
        else if(val.length <= 3  && openDropDown)
            setOpenDropDown(false)
      };    

    const handleTurfColorRating = (e) => {
        let colorVal = (e.target.value !== "")?(parseFloat(e.target.value) < 0?"":parseFloat(e.target.value)):e.target.value;
        switch(e.target.name){
            case "tcF" : (colorVal === "") ? setTcf("") : setTcf(colorVal); break;
            case "tcB" : (colorVal === "") ? setTcb("") : setTcb(colorVal); break;
            case "tcR" : (colorVal === "") ? setTcr("") : setTcr(colorVal); break;
            case "tcL" : (colorVal === "") ? setTcl("") : setTcl(colorVal); break;
            
            case "turfDensity" : setTurfDensity(colorVal); break;
            case "weedDensity" : setWeedDensity(colorVal); break;

            default: break;
        }
    }

    const calc_turfColorRating = () => {
        let tac = [];
        if (tcf !== "") tac.push(tcf);  if (tcb !== "") tac.push(tcb);  if (tcr !== "") tac.push(tcr);  if (tcl !== "") tac.push(tcl);
        if(tac.length){
            setTColor( parseFloat( ( (tac.reduce((partialSum, a) => partialSum + a, 0)) / tac.length ).toFixed(1) ) )    
        }
        else
            setTColor(0)

        calc_lawnScore()
    }

    const calc_lawnScore = () => {
        if(turfDensity !== "" && weedDensity !== ""){
            
            //calc moisture multiplier for lawnscore
            let moisture_vals = [], avg_moisture, moisture_multiplier;
            for(const moist of moisture){
                if (moist !== "") moisture_vals.push(parseFloat(moist));
            }
            if(moisture_vals.length){
                avg_moisture =  parseFloat( ( (moisture_vals.reduce((partialSum, a) => partialSum + a, 0)) / moisture_vals.length ).toFixed(1) ) 
                
                switch (true) {
                    case (avg_moisture > 15 && avg_moisture < 45):  moisture_multiplier = 1;    break;
                    case (avg_moisture <= 15):  moisture_multiplier = 0.9;    break;
                    case (avg_moisture >= 45):  moisture_multiplier = 0.9;    break;
                    default: break;
                }
            }
            
            //calc temperature multiplier for lawnscore
            let temperature_vals = [], avg_temperature, temperature_multiplier
            for(const temp of temperature){
                if (temp !== "") temperature_vals.push(parseFloat(temp));
            }
            if(temperature_vals.length){
                avg_temperature =  parseFloat( ( (temperature_vals.reduce((partialSum, a) => partialSum + a, 0)) / temperature_vals.length ).toFixed(1) ) 
                
                switch (true) {
                    case (avg_temperature > 55 && avg_temperature < 90):  temperature_multiplier = 1;    break;
                    case (avg_temperature <= 55):  temperature_multiplier = 0.9;    break;
                    case (avg_temperature >= 95):  temperature_multiplier = 0.9;    break;
                    default: break;
                }
            }
            
            
            if(moisture_multiplier && temperature_multiplier)
                setLawnScore( parseFloat( ( ( ((parseFloat(turfDensity) + parseFloat(weedDensity)) * 0.75) + (parseFloat(tColor) * 0.25) )  * moisture_multiplier * temperature_multiplier).toFixed(1) ) )
            else if(moisture_multiplier)
                setLawnScore( parseFloat( ( ( ((parseFloat(turfDensity) + parseFloat(weedDensity)) * 0.75) + (parseFloat(tColor) * 0.25) ) * moisture_multiplier).toFixed(1) ) )
            else if(temperature_multiplier)
                setLawnScore( parseFloat( ( ( ((parseFloat(turfDensity) + parseFloat(weedDensity)) * 0.75) + (parseFloat(tColor) * 0.25) ) * temperature_multiplier).toFixed(1) ) )
            else             
                setLawnScore( parseFloat( ( ((parseFloat(turfDensity) + parseFloat(weedDensity)) * 0.75) + (parseFloat(tColor) * 0.25) ).toFixed(1) ) )
        }
        else    setLawnScore(0)

    }

    const formSchema = yup.object().shape({
        
        empID: yup.string().min(4, 'Enter value between 4-6 characters').max(6, 'Enter value between 4-6 characters').required("Employee Number can't be empty"),
        branch: yup.string().matches(/^[0-9]+$|^$/, "Must be only digits").min(4, 'Value must be exactly 4 digits').max(4, 'Value must be exactly 4 digits').typeError("Enter a valid number").required("Branch Number can't be empty").label("Branch Number"),
        
        cAddress: yup.string().min(4, 'Enter minimum 4 characters').required("Customer Address Can't be empty"),
        grassType: yup.string(),
        
        TurfDensity: yup.string().required("Select a Tile").label('Turf Density'),
        WeedDensity: yup.string().required("Select a Tile").label('Weed Density'),
        
        tcF: yup.number()
            .when(['tcB', 'tcR', 'tcL'], {
                is: (tcB, tcR, tcL) => !tcB && !tcR && !tcL && !isLawnDormant,
                then: ()=> yup.number().positive('Value must be a positive number').min(0.1).max(10).label("Turf Color values").typeError("Enter values between 0.1 - 10").transform((v, o) => (o === '' ? null : v)).nullable(true).required('Please enter one of the four fields'),
                otherwise: () => yup.number().positive('Value must be a positive number').min(0.1).max(10).label("Turf Color values").typeError("Enter values between 0.1 - 10").transform((v, o) => (o === '' ? null : v)).nullable(true)
            }),
        
        tcB: yup.number()
            .when(['tcF', 'tcR', 'tcL'], {
                is: (tcF, tcR, tcL) => !tcF && !tcR && !tcL && !isLawnDormant,
                then: ()=> yup.number().positive('Value must be a positive number').min(0.1).max(10).label("Turf Color values").typeError("Enter values between 0.1 - 10").transform((v, o) => (o === '' ? null : v)).nullable(true).required('Please enter one of the four fields'),
                otherwise: () => yup.number().positive('Value must be a positive number').min(0.1).max(10).label("Turf Color values").typeError("Enter values between 0.1 - 10").transform((v, o) => (o === '' ? null : v)).nullable(true)
            }),

        tcR: yup.number()
            .when(['tcF', 'tcB', 'tcL'], {
                is: (tcF, tcB, tcL) => !tcF && !tcB && !tcL && !isLawnDormant,
                then: ()=> yup.number().positive('Value must be a positive number').min(0.1).max(10).label("Turf Color values").typeError("Enter values between 0.1 - 10").transform((v, o) => (o === '' ? null : v)).nullable(true).required('Please enter one of the four fields'),
                otherwise: () => yup.number().positive('Value must be a positive number').min(0.1).max(10).label("Turf Color values").typeError("Enter values between 0.1 - 10").transform((v, o) => (o === '' ? null : v)).nullable(true)
            }),

        tcL: yup.number()
            .when(['tcF', 'tcB', 'tcR'], {
                is: (tcF, tcB, tcR) => !tcF && !tcB && !tcR && !isLawnDormant,
                then: ()=> yup.number().positive('Value must be a positive number').min(0.1).max(10).label("Turf Color values").typeError("Enter values between 0.1 - 10").transform((v, o) => (o === '' ? null : v)).nullable(true).required('Please enter one of the four fields'),
                otherwise: () => yup.number().positive('Value must be a positive number').min(0.1).max(10).label("Turf Color values").typeError("Enter values between 0.1 - 10").transform((v, o) => (o === '' ? null : v)).nullable(true)
            }),
        
        lawnDormant: yup.boolean(),

        tempF: yup.number().min(-50).max(150).nullable(true).transform((cVal, orgVal) => {return orgVal === "" ? null : cVal;}).typeError("Enter value between -50.0F to 150.0F").label("Temperature"),
        tempB: yup.number().min(-50).max(150).nullable(true).transform((cVal, orgVal) => {return orgVal === "" ? null : cVal;}).typeError("Enter value between -50.0F to 150.0F").label("Temperature"),
        tempR: yup.number().min(-50).max(150).nullable(true).transform((cVal, orgVal) => {return orgVal === "" ? null : cVal;}).typeError("Enter value between -50.0F to 150.0F").label("Temperature"),
        tempL: yup.number().min(-50).max(150).nullable(true).transform((cVal, orgVal) => {return orgVal === "" ? null : cVal;}).typeError("Enter value between -50.0F to 150.0F").label("Temperature"),
        
        moistureF: yup.number().positive('Value must be a positive number').min(0).max(100).nullable(true).transform((cVal, orgVal) => {return orgVal === "" ? null : cVal;}).typeError("Enter value between 0.0 - 100.0").label("Moisture"),
        moistureB: yup.number().positive('Value must be a positive number').min(0).max(100).nullable(true).transform((cVal, orgVal) => {return orgVal === "" ? null : cVal;}).typeError("Enter value between 0.0 - 100.0").label("Moisture"),
        moistureR: yup.number().positive('Value must be a positive number').min(0).max(100).nullable(true).transform((cVal, orgVal) => {return orgVal === "" ? null : cVal;}).typeError("Enter value between 0.0 - 100.0").label("Moisture"),
        moistureL: yup.number().positive('Value must be a positive number').min(0).max(100).nullable(true).transform((cVal, orgVal) => {return orgVal === "" ? null : cVal;}).typeError("Enter value between 0.0 - 100.0").label("Moisture"),

        weed1: yup.string(),
        weed2: yup.string(),
        weed3: yup.string(),
        weed4: yup.string()
    // },[['tcF', 'tcB'], ['tcF', 'tcR'], ['tcF', 'tcL'], ['tcB', 'tcR'], ['tcB', 'tcL'], ['tcR', 'tcL']])
    },[['tcF', 'tcB'], ['tcF', 'tcR'], ['tcF', 'tcL'], ['tcB', 'tcR'], ['tcB', 'tcL'], ['tcR', 'tcL']])
    
    
   
    const {register, handleSubmit, setError, clearErrors, formState: {errors}} = useForm({
        mode: 'all',
        resolver: yupResolver(formSchema)
    });

    // const planDetails = {
    //     "TruPro": {noOfApps: 8, expandedServices: "Aeration (Seeding) & Preventative Grub"},
    //     "TruCore": {noOfApps: 8, expandedServices: "Grub Prevention"},
    //     "TruYou": {noOfApps: 7, expandedServices: "None"},
    //     "TruBasic": {noOfApps: 5, expandedServices: "None"},
    // }

    const customToastClass = {        className: "w-[300px] text-[14px] sm:text-[16px] ml-[18vw] xs:-ml-[3vw]"    };

    const onSubmit = async(data) => {

        if(openDropDown)
        {
            document.getElementsByName('cAddress')[0].focus();
            setError("cAddress1", { type: "manual", message: "Please select an address" }) 
            toast.error("Please select an address", customToastClass)
            return false
        }
        setLoading(true)

        data.isLawnDormant = isLawnDormant
        data.lawnScore = lawnScore
        data.turfColor = tColor
        data.grassType = grassType
        data.weed4 = weed4;     data.weed3 = weed3;     data.weed2 = weed2;     data.weed1 = weed1;

        const planScore = parseFloat((process.env.REACT_APP_DESIRED_LAWN_SCORE - lawnScore).toFixed(1));

        let planName, expandedServices, program;
        switch(true){
            case (planScore > 1.5): planName = "TruPro"; break;
            case (planScore >= 1 && planScore <= 1.5): planName = "TruCore"; break;
            case (planScore < 1): planName = "TruYou"; break;
            default: planName = "TruYou"; break;
        }

        //console.log(data.branch, planName);
        try{
            const program_response = await fetch(`${process.env.REACT_APP_LAP_GETPROGRAM_URL}?BranchID=${data.branch}&ServiceName=${planName}`);
            program = await program_response.json()    
            if(program["Error"]){
                // throw program["Error"]
                // eslint-disable-next-line no-throw-literal
                throw "Unknown Branch."
            }
            if(program[planName].noOfApps === 0)
                planName = "TruPro" // set default plan, if #of apps is 0
            
        }catch(e){
            program = {
                    "TruPro": {noOfApps: 8, expandedServices: ""},
                    "TruCore": {noOfApps: 8, expandedServices: ""},
                    "TruYou": {noOfApps: 7, expandedServices: ""},
                    "TruBasic": {noOfApps: 5, expandedServices: ""},
                }
            console.log("Error while getting plan details: "+e);
        }
        //console.log(program);

        expandedServices = program[planName].expandedServices === ""?"None":program[planName].expandedServices
        
        const finalData = {"RecommendedPlan": planName, "LawnApplicationIncluded": program[planName].noOfApps+"", "ExpendedServiceIncluded": expandedServices, "PostalCode": "","LeadId": lead_ID}
        
        for(const key of Object.keys(data).reverse()){
            //console.log(key, data[key]);
            switch(key){
                case "weed1": case "weed2": case "weed3": case "weed4": 
                    if( "WeedIdentification" in finalData)   finalData["WeedIdentification"].push(data[key]);
                    else    finalData["WeedIdentification"] = [data[key]]; 
                break;

                case "tcF": case "tcB": case "tcR": case "tcL": 
                    if( "TurfColoring" in finalData)   finalData["TurfColoring"].push(data[key]);
                    else    finalData["TurfColoring"] = [data[key]]; 
                break;

                case "tempF": case "tempB": case "tempR": case "tempL": 
                    if( "Temperature" in finalData)   finalData["Temperature"].push(data[key]);
                    else    finalData["Temperature"] = [data[key]]; 
                break;

                case "moistureF": case "moistureB": case "moistureR": case "moistureL": 
                    if( "MoisturePercentage" in finalData)   finalData["MoisturePercentage"].push(data[key]);
                    else    finalData["MoisturePercentage"] = [data[key]]; 
                break;

                case "TurfDensity": finalData["TurfDensity"] = data[key]; break;
                case "WeedDensity": finalData["WeedDensity"] = data[key]; break;
                case "cAddress": finalData["CustomerAddress"] = data[key]; break;
                case "empID": finalData["EmployeeNumber"] = data[key]; break;
                case "branch": finalData["BranchNumber"] = data[key]+""; break;
                case "grassType": finalData["GrassType"] = data[key] === null?"":data[key]; break;
                case "turfColor": finalData["TurfAvgColor"] = data[key]; break;
                case "lawnScore": finalData["TotalLawnScore"] = data[key]; break;
                case "isLawnDormant": finalData["IsLawnDormant"] = data[key]; break;

                default: break;
            }

        }
        finalData["TurfColoring"] = finalData["TurfColoring"].reverse().join(",")
        finalData["Temperature"] = finalData["Temperature"].join(",")
        finalData["MoisturePercentage"] = finalData["MoisturePercentage"].join(",")
        finalData["WeedIdentification"] = finalData["WeedIdentification"].join(",")
        
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify( finalData ),
        };

        //console.log(JSON.stringify( requestOptions ), JSON.stringify( finalData ));
        //console.log(data, finalData);

        const response = await fetch(process.env.REACT_APP_LAP_INSERTDATA_URL, requestOptions);
        
        setLoading(false)
        
        if(response.status === 200){
            const outputResponse = await response.json();
            toast.success(outputResponse.Message, customToastClass)
            setTimeout(() => {
                navigate("/result", {state: {formData: {finalData, LAID: outputResponse.LawnAssessmentid, employeeName, branchEmail, employeePhone, customerEmail, customerName, customerPhone, customerID, OpportunityID, lead_ID} } } )
            }, 2000)
        }
        else if(response.status === 400){
            const outputResponse = await response.json();
            toast.error(outputResponse.Message, customToastClass)
        }
        else    toast.error("Some Error Occured.", customToastClass)
        
    }

    const variants = {
        enter: (direction) => { return { x: direction > 0 ? 1000 : -1000, opacity: 0 };},
        center: { zIndex: 1, x: 0, opacity: 1 },
        exit: (direction) => { return { zIndex: 0, x: direction < 0 ? 1000 : -1000, opacity: 0 }; }
    };

    const grassTypes = ["", "Bluegrass", "Ryegrass", "Bentgrass", "Fine Fescue", "Tall Fescue", "Bermudagrass", "Zoysiagrass", "Buffalograss", "Kikuyugrass", "Seashore Paspalum", "Centipedegrass", "St. Augustinegrass", "Bahiagrass", "Carpetgrass", "Cool-Season Blend", "Warm-Season Blend"].sort()

    const grassOptions = []
    for(let i of grassTypes){
        if(i === "") grassOptions.push({name: "Select Grass Type", value: i})
        else grassOptions.push({name: i, value: i})
    }
    //console.log(grassOptions);

    useEffect(() => {
        //console.log(errors);
        
        if(searchParams.get("emp")){
            document.getElementById('empID').value =searchParams.get("emp") || ""
            document.getElementById('empID').focus()
        }
        if(searchParams.get("branch")){
            document.getElementById('branch').value = searchParams.get("branch") || "";
            document.getElementById('branch').focus()
        }
        
        if(!Object.keys(errors).length)                
            window.scrollTo(0, 0)
    }, [errors, searchParams])
    


  return (
    <motion.div className='border border-gray-300'  key="form" initial="enter" animate="center" exit="exit" variants={variants}
    transition={{
        x: { type: "tween", stiffness: 300, damping: 30 },
        opacity: { duration: 0.2 }
    }}>
    {loading && <div className='w-[100vw] h-[100vh] flex justify-center items-center bg-gray-50 opacity-70 fixed top-0 left-0 text-center z-30'><ReactLoading type="spin" color="#00B140" /></div>}

    <div className="" >
        <ToastContainer position='bottom-right' style={{right: 0}} autoClose={1000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </div>
    
    <div className='h-auto'>

        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col justify-center items-center bg-[#F1F1F1]'>

            <div className={`${((errors.empID || errors.branch  || errors.cAddress )?'h-auto md:h-[317px]': 'h-[387px] md:h-[261px] lg:h-[277px]')} w-full flex flex-col justify-between px-[16px] md:px-[40px] lg:px-[80px] py-[20px] md:py-[30px] bg-gradient-to-r from-[#046A38] to-[#00B140]`} >
            
                <div className='flex flex-col md:flex-row justify-between items-center w-full'>
                    <div className='w-[358px] md:w-[328px] lg:w-[400px] font-sspBold text-[18px] md:text-[24px] lg:text-[30px] text-white mb-[18px] md:mb-0 '>Lawn Assessment Application</div>
                </div>
            

                <div className='flex flex-col md:flex-row justify-between items-center w-full'>
                    <div className='w-[358px] md:w-[328px] lg:w-[400px]'>
                        <label className='text-white font-sspReg text-[16px] lg:text-[18px] leading-[18px] tracking-[0.29px] lg:leading-[20px] lg:tracking-[0.36px]' htmlFor="empID">*EMPLOYEE NUMBER</label><br/>
                        <input type='text' name="empID" placeholder='Enter Employee Number' {...register("empID")} className={`border ${(errors.empID ?'border-[#FF0000]':'border-[#A0A0A0]')} text-[14px] pl-[10px] w-full h-[36px] mt-[8px] md:mt-[10px] placeholder:italic tracking-[0.32px]`} id="empID"/>
                        {errors.empID && <div className='errorMsg font-sspReg'>{errors.empID?.message}</div>}
                    </div>
                    <div className='w-[358px] md:w-[328px] lg:w-[400px] mt-[12px] md:mt-0'>
                        <label className='text-white font-sspReg text-[16px] lg:text-[18px] leading-[18px] tracking-[0.29px] lg:leading-[20px] lg:tracking-[0.36px] ' htmlFor="branch">*BRANCH NUMBER</label><br/>
                        <input type='number' inputMode='numeric' name="branch" placeholder='Enter Branch Number' {...register("branch")} className='border border-[#A0A0A0] text-[14px] pl-[10px] w-full h-[36px] mt-[8px] md:mt-[10px] placeholder:italic tracking-[0.32px]' id="branch"/>
                        {errors.branch && <div className='errorMsg font-sspReg'>{errors.branch?.message}</div>}
                    </div>
                </div>

                <div className='flex flex-col md:flex-row justify-between items-center w-full mt-[12px] md:mt-0'>
                    <div className='w-[358px] md:w-[328px] lg:w-[400px]'>
                        <label className='text-white font-sspReg text-[16px] lg:text-[18px] leading-[18px] tracking-[0.29px] lg:leading-[20px] lg:tracking-[0.36px]' htmlFor="cAddress">*ADDRESS</label><br/>
                        <div className=''><input type='text' placeholder='Enter Address' {...register("cAddress")} autoComplete='off' className={`border border-[#A0A0A0] text-[14px] pl-[10px] w-full h-[36px] mt-[8px] md:mt-[10px] placeholder:italic tracking-[0.32px] ${errors.cAddress1 ?'border border-[#FF0000]':''}`} onChange={loadAddress} value={addressval} name="cAddress" id="cAddress"/></div>
                        <AddressAutoComplete address={addressval} setAddressVal={setAddressVal} setOpenDropDown={setOpenDropDown} openDropDown={openDropDown} errors={errors} clearErrors={clearErrors}/>
                        {errors.cAddress && <div className='errorMsg font-sspReg'>{errors.cAddress?.message}</div>}
                    </div>

                    <div className='w-[358px] md:w-[328px] lg:w-[400px] mt-[12px] md:mt-0'>
                        <span className='text-white font-sspReg text-[16px] lg:text-[18px] leading-[18px] tracking-[0.29px] lg:leading-[20px] lg:tracking-[0.36px]' >GRASS TYPE</span><br/>
                        <div className="flex">
                            <span className='border border-[#A0A0A0] text-[14px] w-full h-[36px] mt-[8px] md:mt-[10px] tracking-[0.32px] bg-white'>
                                <SelectSearch options={grassOptions} value={grassType} placeholder="Select Grass Type" search={true} autoComplete='off' onChange={setGrassType} onFocus={() => setIsopen(true)} onBlur={() => setIsopen(false)} filterOptions={[(options, query) => {
                                    if(query === "")  return options
                                    const filteredOptions = options.filter(option => {
                                        let opt = option.value.toLowerCase()
                                    
                                        if (query.length > opt.length) return false;
                                        
                                        let pattern = new RegExp(query.toLowerCase()) // ("^"+query.toLowerCase())
                                        let result = opt.match(pattern);
                                        if(result?.input)  return result.input
                                        return false
                                    })
                                    return filteredOptions
                                }]}  />

                            </span>

                            <div className="-ml-[22px] mt-[25px]">
                                {!isopen && <img src={dropDown} alt="dropdownIcon"/>}
                                {isopen && <img src={dropUp} alt="dropupIcon"/>}
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <div className='px-[16px] md:px-[40px] lg:px-[80px] mt-[20px] md:mt-[30px]'>

                <div className={`bg-white px-[14px] md:px-[18px] lg:px-[32px] w-[358px] md:w-[688px] lg:w-[864px] rounded-md shadow-[0px_3px_6px_#00000029] py-[14px] mb-[24px] flex flex-col justify-between ${(errors.TurfDensity?'border border-[#FF0000] h-[291px] md:h-[247px] lg:h-[243px] ':'h-[267px] md:h-[243px] lg:h-[215px] ')}`}>
                    
                    <div className='text-[#046A38] text-[18px] font-sspBold'>*TURF DENSITY
                    <input type="text" value={turfDensity} {...register("TurfDensity")} className='text-white w-0' name='TurfDensity' readOnly/></div>
                    
                    <p className='text-[14px] text-[#303236] font-sspReg leading-[16px] lg:leading-[18px] -mt-[10px]'>(Choose a tile, that most closely corresponds to the current turf density in the lawn. 1 = 100% bare, 5 = no bare areas present)</p>
                    
                    {errors.TurfDensity && <div className='errorMsg1 font-sspReg'>{errors.TurfDensity?.message}</div>}
                    
                    <div className='pb-[20px] md:pb-0 overflow-x-scroll md:overflow-x-hidden overflow-y-hidden h-scrollbar '>
                        <Tile type="Turf" method={setTurfDensity} errors={errors} ></Tile>
                    </div>
                    
                </div>

                <div className={`bg-white px-[14px] md:px-[18px] lg:px-[32px] w-[358px] md:w-[688px] lg:w-[864px] rounded-md shadow-[0px_3px_6px_#00000029] py-[14px] mb-[24px] flex flex-col justify-between ${(errors.WeedDensity?'border border-[#FF0000] h-[291px] md:h-[247px] lg:h-[243px] ':'h-[267px] md:h-[243px] lg:h-[215px] ')}`}>
                    
                    <div className='text-[#046A38] text-[18px] font-sspBold' >*WEED DENSITY
                    <input type="text" value={weedDensity} {...register("WeedDensity")} className='text-white w-0' name='WeedDensity' readOnly/></div>
                    
                    <p className='text-[14px] text-[#303236] font-sspReg leading-[16px] lg:leading-[18px] -mt-[10px]'>(Choose a tile, that most closely corresponds to the current weed density in the lawn. 1 = 100% bare, 5 = no weeds present)</p>
                    
                    {errors.WeedDensity && <div className='errorMsg1 font-sspReg'>{errors.WeedDensity?.message}</div>}
                    
                    <div className={`pb-[20px] md:pb-0 overflow-x-scroll md:overflow-x-hidden overflow-y-hidden h-scrollbar `}> 
                        <Tile type="Weed" method={setWeedDensity} errors={errors} ></Tile>
                    </div>
                    
                </div>
                
                <div className={`bg-white px-[14px] md:px-[18px] lg:px-[32px] w-[358px] md:w-[688px] lg:w-[864px] rounded-md shadow-[0px_3px_6px_#00000029] py-[14px] mb-[24px] flex flex-col justify-between ${((errors.tcF || errors.tcB || errors.tcR || errors.tcL)?'border border-[#FF0000]':'')} h-auto`}>
                    
                    <div className='text-[#046A38] text-[18px] font-sspBold' >*TURF COLOR RATING</div>
                    <p className='text-[14px] text-[#303236] font-sspReg leading-[16px] lg:leading-[18px] pb-[10px]'>(Use the GreenIndex iOS app to capture a Color Rating from F, B, R & L areas of Lawn. Input reading from each area into corresponding box below. Ratings range : 0.1 - 10.0)</p>
                    
                    {/* {(errors.tcF || errors.tcB || errors.tcR || errors.tcL) && <div className='errorMsg1'>{(errors.tcF ? errors.tcF.message : (errors.tcB ? errors.tcB.message : (errors.tcR ? errors.tcR.message : (errors.tcL ? errors.tcL.message : '') ) ) ) }</div>} */}
                    {(errors.tcF || errors.tcB || errors.tcR || errors.tcL) && <div className='errorMsg1 font-sspReg mb-1'>Enter values between 0.1 - 10</div>}
                    
                    {/* F,B,R,L Values */}
                    
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-rows-1 grid-flow-row lg:grid-flow-col gap-4 md:w-[400px] lg:w-full'>
                        
                        <div className='flex md:justify-start lg:justify-center items-center'>
                            <label className='text-[#303236] md:text-[14px] lg:text-[16px] w-[46px] lg:w-[48px] mr-[8px] lg:mr-[14px]' htmlFor="tcF">FRONT</label>
                            <Counter handleTurfColorRating={handleTurfColorRating} calc_turfColorRating={calc_turfColorRating} register={register} eleName="tcF" eleVal={tcf} method={setTcf} errors={errors}/>
                        </div>
                        
                        <div className='flex md:justify-start lg:justify-center items-center'>
                            <label className='text-[#303236] md:text-[14px] lg:text-[16px] w-[46px] lg:w-[48px] mr-[8px] lg:mr-[14px]' htmlFor="tcB">BACK</label> 
                            <Counter handleTurfColorRating={handleTurfColorRating} calc_turfColorRating={calc_turfColorRating} register={register} eleName="tcB" eleVal={tcb} method={setTcb} errors={errors}/>
                        </div>
                        
                        <div className='flex md:justify-start lg:justify-center items-center'>
                            <label className='text-[#303236] md:text-[14px] lg:text-[16px] w-[46px] lg:w-[48px] mr-[8px] lg:mr-[14px]' htmlFor="tcR">RIGHT</label> 
                            <Counter handleTurfColorRating={handleTurfColorRating} calc_turfColorRating={calc_turfColorRating} register={register} eleName="tcR" eleVal={tcr} method={setTcr} errors={errors}/>
                        </div>
                        
                        <div className='flex md:justify-start lg:justify-center items-center'>
                            <label className='text-[#303236] md:text-[14px] lg:text-[16px] w-[46px] lg:w-[48px] mr-[8px] lg:mr-[14px]' htmlFor="tcL">LEFT</label> 
                            <Counter handleTurfColorRating={handleTurfColorRating} calc_turfColorRating={calc_turfColorRating} register={register} eleName="tcL" eleVal={tcl} method={setTcl} errors={errors}/>
                        </div>
                        
                        
                    </div>
                    
                    <div className='mt-3'>
                        <label htmlFor="dormant" style={{fontSize: "0.8rem", letterSpacing: "-0.05rem"}} className="dormantCheck flex items-center">
                            <input type="checkbox" {...register("lawnDormant")} name="lawnDormant" id="dormant" onChange={() => setIsLawnDormant(!isLawnDormant)  } /> 
                            <span className="text-[0.9rem] font-sspReg" >No Color Rating Obtained</span>
                            <span className="checkmark"></span>
                        </label>
                        
                        {isLawnDormant && <div className="dormant px-3 py-2 text-[0.75rem] md:text-[0.8rem] leading-[16px] lg:leading-[18px] text-[#303236]" >A color rating was not included as part of your lawn assessment, due to minimal growth occurring in lawn grasses currently. As temperatures warm up, fertilizer applications included in our recommended program will help promote a dark green color and improve the Turf Density in your lawn. Weed control applied will also help decrease the presence of weeds throughout the season.</div>}
                    </div>
                </div>

                <div className='bg-transparent w-[358px] md:w-[688px] lg:w-[864px] h-[179px] md:h-[127px] px-[14px] md:px-[18px] lg:px-[25px] rounded-md border border-[#A0A0A0] py-[14px] mb-[24px] flex flex-col justify-between'>
                    <div className='flex'>
                        <div className='text-[#046A38] text-[18px] font-sspBold' >LAWN SCORE</div>
                        <input type="number" {...register("lawnScore")} name="lawnScore"  className='hidden' readOnly value={lawnScore}/>
                        <input type="number" {...register("turfColor")} name="turfColor"  className='hidden' readOnly value={tColor}/>
                    </div>

                    <p className='text-[14px] text-[#303236] font-sspReg leading-[16px] lg:leading-[18px] -mt-[4px]'>Based on your selections above, here is the result</p>
                    

                    <div className='grid grid-cols-2 grid-flow-row gap-y-3 md:flex md:justify-between'>
                        <div className='w-[140px] md:w-[145px] lg:w-[180px] h-[42px] font-sspReg flex justify-between items-center bg-gradient-to-r from-[#046A38] to-[#00B140] text-white rounded-md px-[12px]'>
                            <p className='text-[14px] lg:text-[16px]'>TURF DENSITY</p> <p className='text-[20px] lg:text-[24px]'>{turfDensity===""?0:turfDensity}</p>
                        </div>
                        <div className='w-[182px] md:w-[145px] lg:w-[180px] h-[42px] font-sspReg flex justify-between items-center bg-gradient-to-r from-[#046A38] to-[#00B140] text-white rounded-md px-[12px] -ml-[18px] md:-ml-[0px]'>
                            <p className='text-[14px] lg:text-[16px]'>WEED DENSITY</p> <p className='text-[20px] lg:text-[24px]'>{weedDensity===""?0:weedDensity}</p>
                        </div>
                        <div className='w-[140px] md:w-[145px] lg:w-[180px] h-[42px] font-sspReg flex justify-between items-center bg-gradient-to-r from-[#046A38] to-[#00B140] text-white rounded-md px-[12px]'>
                            <p className='text-[14px] lg:text-[16px]'>TURF COLOR </p> <p className='text-[20px] lg:text-[24px]'>{tColor}</p>
                        </div>
                        <div className='w-[182px] md:w-[176px] lg:w-[216px] h-[42px] font-sspReg flex justify-between items-center bg-gradient-to-r from-[#046A38] to-[#00B140] text-white rounded-md px-[12px] -ml-[18px] md:-ml-[0px]'>
                            <p className='text-[14px] lg:text-[16px]'>TOTAL LAWN SCORE</p> <p className='text-[20px] lg:text-[24px]'>{lawnScore*10}</p>
                        </div>
                    </div>
                </div>

                
                <div className={`bg-transparent w-[358px] md:w-[688px] lg:w-[864px] rounded-md flex flex-col md:flex-row justify-between`}>
                    <div className={`bg-white w-[358px] md:w-[338px] lg:w-[420px] p-[14px] md:pl-[18px] md:pr-[9px] lg:px-[24px] md:py-[11px] lg:py-[16px] flex flex-col shadow-[0px_3px_6px_#00000029] rounded  ${((errors.moistureF || errors.moistureB || errors.moistureR || errors.moistureL || errors.tempF || errors.tempB || errors.tempR || errors.tempL)?'border border-[#FF0000]':'')}`}>

                        <div className='text-[#046A38] text-[18px] font-sspBold' >SOIL READINGS</div>
                        <p className='text-[14px] text-[#303236] my-[6px] lg:my-[8px] font-sspReg leading-tight w-[328px] md:w-[292px] lg:w-full'>(Use the Soil Meter to capture a temperature and moisture % reading from F,B,R &L areas of Lawn. Input reading from each area into corresponding box below)</p>
                        
                        <div className={`mt-[4px] flex divide-x-[1px] divide-[#A0A0A0] ${((errors.moistureF || errors.moistureB || errors.moistureR || errors.moistureL || errors.tempF || errors.tempB || errors.tempR || errors.tempL)?'h-[261px]':'h-[216px]')}`}>
                            <div className=' w-[150px] md:w-[160px] lg:w-[180px]'>
                                <div className='text-[#046A38] text-[18px] font-sspBold'>SOIL TEMP(F)</div>
                                {(errors.tempF || errors.tempB || errors.tempR || errors.tempL) && <><div className='errorMsg1 font-sspReg mr-[20px] lg:mr-[50px] md:py-[6px] lg:py-[10px]'>Enter value between -50 to 150</div><br/></>}
                                <FBRL type="temp" register={register} values={temperature} method={setTemperature} errors={errors}/>
                            </div>

                            <div className='pl-[27px] md:pl-[17px] lg:pl-[30px] w-[180px]  md:w-[190px] lg:w-[185px]'>
                                <div className='text-[#046A38] text-[18px] font-sspBold'>SOIL MOISTURE(%)</div>
                                {(errors.moistureF || errors.moistureB || errors.moistureR || errors.moistureL) && <><div className='errorMsg1 font-sspReg mr-[30px] md:py-[6px] lg:py-[10px]'>Enter value between 0 to 100</div><br/></>}
                                <FBRL type="moisture" register={register} values={moisture} method={setMoisture} errors={errors}/>
                            </div>
                        </div>

                    </div>

                    <div className='bg-white w-[358px] md:w-[338px] lg:w-[420px] h-[334px] md:h-[350px] px-[14px] py-[14px] md:px-[18px] lg:px-[24px] md:py-[11px] lg:py-[16px] flex flex-col shadow-[0px_3px_6px_#00000029] rounded mt-[24px] md:mt-0'>
                        <div className='text-[#046A38] text-[18px] font-sspBold'>WEED IDENTIFICATION</div>
                        <p className='text-[14px] text-[#303236] my-[6px] lg:my-[8px] font-sspReg leading-tight w-[328px] md:w-[292px] lg:w-full'>(Use OIR, or visual observation, to identify the 4 most commonly seen weeds in the lawn currently. If no weeds are currently present, choose "none" for each)</p>
                        
                        <div className='flex flex-col justify-between mt-[4px] h-[210px] lg:h-[221px] border-0'>
                            <div className='text-[#046A38] text-[18px] font-sspBold' >WEED TYPE</div>
                            <WeedType weed={weed1} method={setWeed1}/>
                            <WeedType weed={weed2} method={setWeed2}/>
                            <WeedType weed={weed3} method={setWeed3}/>
                            <WeedType weed={weed4} method={setWeed4}/>
                        </div>
                    </div>
                </div>

                <div className='text-center'>
                    <input type='submit' className='w-[150px] h-[40px] md:w-[190px] md:h-[60px] cursor-pointer text-white text-[16px] md:text-[18px] bg-[#046A38] rounded-lg font-sspBold my-[30px] md:my-[40px] tracking-[0.36px] leading-[22px]' value="NEXT"/>
                </div>
            </div>
        </form>
    </div>
    </motion.div>
  )
}

export default Form